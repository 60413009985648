import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, take, tap } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NewUserInterface } from 'src/app/interfaces/newUser';
import { UserInterface } from 'src/app/interfaces/user';
import { User } from '../models/user.model';
import { use } from 'echarts';

@Injectable({ providedIn: 'root' })

export class AuthService {
  user = new BehaviorSubject<UserInterface | null | any>(null);
  signupId!: string;

  constructor(private http: HttpClient, private router: Router) {
    this.autoLogin()
  }

  signup(user: NewUserInterface) {

    let headers = new HttpHeaders(
      {'skip': 'true'});

    return this.http
      .post<string>(
        environment.API_url + 'api/auth/signup',
        {
          company: user.company,
          companyType: user.companyType,
          phone: user.phone,
          fullName: user.fullName,
        }, { headers: headers }
      )
      .pipe(
        catchError(this.handleError),
      );
  }

  completeSignup(user: NewUserInterface){
    return this.http.put(
      environment.API_url + `api/auth/finish/${this.signupId}`,
      {
        mail: user.mail,
        password: user.password,
      }
    )
    .pipe(
      catchError(this.handleError),
    );
  }

  login(mail: string, password: string) {
    let headers = new HttpHeaders(
      {'Content-Type':'application/json; charset=utf-8',
      'Authorization': 'Basic ' + btoa(mail + ':' + password),
      'skip': 'true'
    });

    return this.http
      .post(environment.API_url + 'api/auth/login', null, {headers: headers})
      .pipe(
        catchError(this.handleError),
        tap(resData => {
          this.handleAuthentication(resData);
        })
      );
  }
  autoLogin() {

    const json = localStorage.getItem('userData');
    const userData = JSON.parse(json || '{}');
    const url = this.router.routerState.snapshot.url;

    if (userData?.token) {
      const loadedUser: any = this.newUser(userData);

      if (loadedUser) {
        this.user.next(loadedUser);

        if (url === '/login' || url === '/cadastro') {
           this.router.navigate(['/dashboard']);
        }
      }
    }
  }

  resetPassword(mail: string) {
    let headers = new HttpHeaders(
      { 'skip': 'true' });

    return this.http
      .post(environment.API_url + 'api/auth/reset/password', mail, {headers: headers})
      .pipe(take(1))
  }

  createdPassword(id: string, password: string) {
    let headers = new HttpHeaders(
      {'Content-Type':'application/json; charset=utf-8',
      'Authorization': 'Basic ' + btoa(password),
      'skip': 'true'
    });

    return this.http
      .post(environment.API_url + 'api/auth/recover/' + id, null, {headers: headers})
  }

  passwordUpdate(id: string, oldPassword: string, newPassword: string) {
    let headers = new HttpHeaders(
      {'Content-Type':'application/json; charset=utf-8',
      'pwd': 'Basic ' + btoa(oldPassword + ':' + newPassword),
    });

    return this.http
      .put(environment.API_url + 'secure-api/user/passwordUpdate/' + id, null, {headers: headers})
  }
  logout() {

    if(this.user.value) {
      this.logoutByInvalidToken();
    } else {
      let headers = new HttpHeaders(
        {'Content-Type':'application/json; charset=utf-8',
        'Authorization': 'Basic ' + this.user?.value?.token});

      this.http
        .post(environment.API_url + 'api/auth/logout', null, { headers: headers })
        .pipe(take(1))
        .subscribe(
          (resp) => {
            this.router.navigate(['/login']);
            localStorage.removeItem('userData');
            this.user.next(null);
          },
          (err) => { this.logoutByInvalidToken()}
        )
    }

  }

  logoutByInvalidToken() {
    this.user.next(null);
    this.router.navigate(['/login']);
    localStorage.removeItem('userData');
    sessionStorage.removeItem('firstTime');
  }

  isAuthenticated(): boolean {
    return !!localStorage.getItem('userData');
  }

  updateStatus(id: string, status: any) {
    return this.http.put(environment.API_url + 'secure-api/tenants/status/' + id, status)
  }

  private handleAuthentication(user: any) {
    const _user: any = this.newUser(user)

    localStorage.setItem('userData', JSON.stringify(_user));
    this.user.next(_user);
  }

  private newUser(user: any) {
    const _user = new User(
      user.fullName,
      user.phone,
      user.mail,
      user.id,
      user.token,
      user.userType,
      user.enable,
      user.lastLogin,
      user.tenant = {
        companyId: user.tenant.companyId,
        companyType: user.tenant.companyType,
        id: user.tenant.id,
        companyName: user.tenant.companyName,
        companyCnpj: user.tenant.companyCnpj,
        companyCompletionPercentage: user.tenant.companyCompletionPercentage,
        tenantStatus: user.tenant.tenantStatus,
        sellCcb: user.tenant.sellCcb,
        ccbBuyer: user.tenant.ccbBuyer
      });

    return _user;
  }

  changeUser(user: any) {
    localStorage.getItem('userData');
    localStorage.setItem('userData', JSON.stringify(user));
    this.user.next(user);
  }

  private handleError(errorRes: HttpErrorResponse) {
    let errorMessage;

    if (!errorRes.error || !errorRes.error.error) {
      return throwError(errorMessage);
    }

    const getError = errors[errorRes.error.message];

    if (getError) { errorMessage = errors[errorRes.error.message]() }

    return throwError(errorMessage);
  }

  getStatus() {
    return this.http.get(environment.API_url + 'secure-api/tenants/status')
  }

  getSignupId(){
    return this.signupId;
  }

  setSignupId(signupId: string){
    this.signupId = signupId;
  }
}


const errors: any = {
  'CPF_ALREADY_REGISTERED': () => { return 'Já existe um cadastro realizado com este CPF' },
  'MAIL_ALREADY_REGISTERED': () => { return 'Já existe um cadastro realizado com este e-mail' },
  'CNPJ_ALREADY_REGISTERED': () => { return 'Já existe um cadastro realizado com este CNPJ' },
  'MAIL_DO_NOT_EXISTS': () => { return 'O e-mail informado não está cadastrado.' },
  'INVALID_PASSWORD': () => { return 'O e-mail informado não está cadastrado ou a senha está incorreta. Verifique os dados digitados e tente entrar novamente' },
  'INVALID_CNPJ': () => {return 'O CNPJ informado é inválido.'},
  'PHONE_ALREADY_REGISTERED': () => { return 'Já existe um cadastro realizado com este telefone' },
}
