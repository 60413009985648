import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
registerLocaleData(localePt, 'pt');

import { CurrencyMaskConfig, CurrencyMaskModule, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';
import { AngularMaterialModule } from './angular-material.module';
import { NgxMaskModule } from 'ngx-mask';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BlankslateComponent } from '../components/blankslate/blankslate.component';
import { LoadingComponent } from '../components/loading/loading.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InternalErrorComponent } from '../components/internal-error/internal-error.component';
import { AuthInterceptorService } from '../interceptor/auth-interceptor.service';
import { ContextLoadingComponent } from '../components/context-loading/context-loading.component';
import { ViewerDocumentsComponent } from '../components/viewer-documents/viewer-documents.component';
import { MyComponentComponent } from '../pages/operation/operation-document-viewer/documents/my-component.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AsideComponent } from '../components/aside/aside.component';
import { SelectBankComponent } from '../components/select-bank/select-bank.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DocViewerComponent } from '../components/document-viewer/document-viewer.component';
import { LastDigitDirective } from '../directives/last-digit.directive';
import { ExpansionPanelComponent } from './../components/expansion-panel/expansion-panel.component';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import 'moment/locale/pt-br';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SocialMediaComponent } from '../components/social-media/social-media.component';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "left",
  allowNegative: false,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: "."
};


@NgModule({
  declarations: [
    BlankslateComponent,
    LoadingComponent,
    ContextLoadingComponent,
    InternalErrorComponent,
    ViewerDocumentsComponent,
    MyComponentComponent,
    AsideComponent,
    SelectBankComponent,
    DocViewerComponent,
    LastDigitDirective,
    ExpansionPanelComponent,
    SocialMediaComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    NgxMaskModule.forRoot(),
    CurrencyMaskModule,
    ReactiveFormsModule,
    FormsModule,
    AngularEditorModule,
    NgxMatSelectSearchModule,
    InfiniteScrollModule
  ],
  exports: [
    FormsModule,
    NgxMaskModule,
    CommonModule,
    CurrencyMaskModule,
    AngularMaterialModule,
    BlankslateComponent,
    LoadingComponent,
    ContextLoadingComponent,
    ReactiveFormsModule,
    InternalErrorComponent,
    ViewerDocumentsComponent,
    MyComponentComponent,
    AngularEditorModule,
    AsideComponent,
    NgxMatSelectSearchModule,
    SelectBankComponent,
    DocViewerComponent,
    LastDigitDirective,
    ExpansionPanelComponent,
    InfiniteScrollModule,
    SocialMediaComponent
  ],
  providers: [
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    { provide: LOCALE_ID, useValue: 'pt-BR'},
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL'},
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class SharedModule {
}
