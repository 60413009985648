<div class="container-auth">
	<div class="tabs">

		<mat-tab-group class="w-100" mat-align-tabs="center" mat-stretch-tabs (selectedTabChange)="onTabChanged($event.index)"
		[selectedIndex]="currTab">
		<mat-tab label="Login">
			<app-login></app-login>
		</mat-tab>
		</mat-tab-group>

		<!-- <p class="text-secondary text-center">v{{currentApplicationVersion}}</p> -->
	</div>

	<div class="content">
		<img class="mx-auto mt-3 d-block" width="180" height="auto" src="https://s3-sa-east-1.amazonaws.com/antecipagov.public.docs/images/BROADFACTOR_MARCA_FUNDOCINZA_40%20(1).png" alt="logo broadfactor">

		<img class="svg" src="../../../../assets/auth_page.svg" alt=""  rel="preload">
	</div>

	<!-- <button class="mt-2 mr-2 help-button" mat-fab color="primary" matTooltip="Preciso de ajuda" aria-label="Preciso de ajuda" (click)="goToServiceDesk()">
		<mat-icon>help</mat-icon>
	</button> -->
</div>
