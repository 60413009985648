import { ManagementUsersComponent } from './pages/management-users/management-users.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ExternalProposalComponent } from './pages/proposal/external-proposal/external-proposal.component';
import { ForgetPasswordComponent } from './pages/auth/login/forget-password/forget-password.component';
import { ChangePasswordComponent } from './pages/auth/login/change-password/change-password.component';
import { DisabledUserComponent } from './components/disabled-user/disabled-user.component';
import { AuthComponent } from './pages/auth/auth/auth.component';
import { RoutePages } from './enum/route-pages.enum';
import { AuthGuard } from './guards/auth.guard';
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';

const routes: Routes = [
  {path: RoutePages.Login, component: AuthComponent},
  {path: RoutePages.RecoverPassword, component: ForgetPasswordComponent},
  {path: RoutePages.SignUp, loadChildren: () => import(`./modules/signup.module`).then(m => m.SignupModule)},
  {path: RoutePages.ChangePassword + '/:id', component: ChangePasswordComponent},
  {path: RoutePages.ResetPassword, component: ResetPasswordComponent},
  {path: RoutePages.ExternalProposal + '/:id', component: ExternalProposalComponent },
  {path: RoutePages.Validation, component: DisabledUserComponent, canActivate: [AuthGuard]},

  {path: RoutePages.CreditOperation,  loadChildren: () => import(`./modules/operation.module`).then(m => m.CreditOperationModule), canActivate: [AuthGuard]},
  {path: RoutePages.Dashboard,  loadChildren: () => import(`./modules/dashboard.module`).then(m => m.DashboardModule), canActivate: [AuthGuard]},
  {path: RoutePages.Financial,  loadChildren: () => import(`./modules/financial.module`).then(m => m.FinancialModule), canActivate: [AuthGuard]},
  {path: RoutePages.SettingsInitial,  loadChildren: () => import(`./modules/settings.module`).then(m => m.SettingModule), canActivate: [AuthGuard]},
  {path: RoutePages.Settings,  loadChildren: () => import(`./modules/settings.module`).then(m => m.SettingModule), canActivate: [AuthGuard]},
  {path: RoutePages.Quotes,  loadChildren: () => import(`./modules/quotation.module`).then(m => m.QuotationModule), canActivate: [AuthGuard]},
  {path: RoutePages.Proposals,  loadChildren: () => import(`./modules/proposal.module`).then(m => m.ProposalModule), canActivate: [AuthGuard]},
  {path: RoutePages.ManagementUsers,  loadChildren: () => import(`./modules/management-users.module`).then(m => m.ManagementUsersModule), canActivate: [AuthGuard]},

  {path: '', redirectTo: '/login', pathMatch: 'full' },
  {path: '**', redirectTo: '/login' ,pathMatch: 'full'}
]

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
